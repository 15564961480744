var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        !_vm.showConfirmButton ? _c("add-request-slots") : _vm._e(),
        _vm.runTimer && _vm.showConfirmButton
          ? _c("confirm-slots", {
              attrs: { "get-sloat-price-data": _vm.getSloatPriceData },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }