<template>
  <div>
    <div>
      <add-request-slots v-if="!showConfirmButton" />
      <confirm-slots
        v-if="runTimer && showConfirmButton"
        :get-sloat-price-data="getSloatPriceData"
      />
    </div>
  </div>
</template>

<script>
import {
  DR_GET_RUN_TIMER,
  DR_GET_SLOAT_PRICE_DATA,
  DR_UPDATE_RUN_TIMER,
} from '@/store/actions'
import { getIsAndroid, removeTerminalId } from '@/core'
import { mapActions, mapGetters } from 'vuex'
import AddRequestSlots from '@/views/drivers-home/components/AddRequestSlots'
import ConfirmSlots from '@/views/drivers-home/components/ConfirmSlots'

export default {
  name: 'GettingSlots',
  components: { AddRequestSlots, ConfirmSlots },
  data() {
    return {
      hasQuota: false,
      noQuota: false,
    }
  },
  computed: {
    ...mapGetters({
      runTimer: DR_GET_RUN_TIMER,
      getSloatPriceData: DR_GET_SLOAT_PRICE_DATA,
    }),
    showConfirmButton() {
      let slots = this.getSloatPriceData.filter(
        item => item['state_system'] === 'time_given',
      )

      return slots.length > 0
    },
    isAndroid() {
      return getIsAndroid()
    },
  },
  watch: {
    getSloatPriceData: {
      handler(value) {
        let noActiveQuota = []

        if (value.length > 0) {
          noActiveQuota = value.filter(
            quota => quota.state_system !== 'no_quota',
          )
          noActiveQuota.length === 0
            ? this.$message.error(
                'По данным параметрам нет доступных квот, измените параметры запроса',
              )
            : null

          value.map(item =>
            item.state_system !== 'no_quota'
              ? (this.hasQuota = true)
              : (this.noQuota = true),
          )
          let activeSlot = value.filter(item => {
            if (
              !item.timeslot?.deleted_at &&
              item['state_system'] !== 'time_submitted'
            ) {
              return true
            }
          })

          if (!activeSlot.length) {
            this.updateRunTime(false)
            localStorage.removeItem('get-slot-form')
            removeTerminalId()
            this.$router.push({ name: 'Timeslots' })
          }
        }
      },
    },
  },
  methods: {
    ...mapActions({
      updateRunTime: DR_UPDATE_RUN_TIMER,
    }),
  },
}
</script>

<style lang="sass">
.drivers-main-container
  background: white
  padding: 55px 15px 100px !important
  max-height: unset !important
  overflow: auto !important
  min-width: 320px
  max-width: 1024px
  margin: 0 auto !important
  &.android
    margin-top: 0
    padding: 40px 15px 80px
    max-height: unset
  .el-form-item__label
    margin-bottom: 6px
    width: 100% !important
    &:before
      font-size: 14px
  i
    @media only screen and (max-width: 550px)
      &:before
        font-size: 14px!important
.el-message
  top: 20%!important
.el-message > p
  text-transform: none!important
</style>
